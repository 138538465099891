body {
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Fira Sans', sans-serif;
  font-weight: 400;
}