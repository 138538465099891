.btn-apply {
    background: #4285f4;
    color: white;
}

.btn-apply:hover {
    background: #e53e3e;
}

.google-logo {
    display: inline-block;
    font: inherit;
    height: 1em;
    vertical-align: -0.15em;
}